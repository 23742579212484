import React from 'react';

const PillarVertical = ({ children, ...rest }) => (
  <div className="pillar v" {...rest}>
    <div>
      { children }
    </div>
  </div>
);

const PillarHorizontal = ({ children, ...rest }) => (
  <div className="pillar h" {...rest}>
    <div>
      { children }
    </div>
  </div>
);

export { PillarVertical, PillarHorizontal };