import React, { useState } from "react";
import { PillarVertical } from '../pillars';
import { Link } from 'gatsby';
import Layout from '../layout';
import Container from '../container';

const Footer = () => {
  const [ name, setName ] = useState("");
  const [ phone, setPhone ] = useState("");

  const enterName = e => {
    const pattern = /[А-Яа-я\s]/;
    const { value } = e.target;
    if (value.match(pattern) || value === "") setName(value);
  };

  const enterPhone = e => {
    const { value } = e.target;
    if (value.length <= 10 || value === "") setPhone(value);
  };

  return (
    <Layout className="footer section-bg-dark" id="contact-us">
      <div className="wrapper-outer">
        <h2>CONTACT US</h2>
        <div className="wrapper-inner">

          <Container>
            <div className="content">
              <div className="left-side">
                <PillarVertical style={{ top: -110, left: "100%" }} />

                <div className="company-description">
                  <img src="/icons/logo_big.svg" alt="logo" />
                  <p>
                    Наша команда разработала концепцию идеального места для жизни и отдыха в лучшей локации г.  Каменское! В непосредственной близости находятся новые инфраструктурные объекты (Эпицентр, в скором времени будет построен современный торгово-развлекательный комплекс), что обеспечит максимальное удобство и экономию времени, которое можно отлично провести с семьей на территории комплекса Dresden, наслаждаясь роскошным бассейном и авторским ландшафтным дизайном!
                  </p>

                  <div>
                    <Link to="/info">
                      <span className="to-important-info">ВАЖНАЯ ИНФОРМАЦИЯ</span>
                    </Link>
                    <span>Все права сохранены. 2019 Dresden. Creative House Group</span>
                  </div>
                </div>
              </div>

              <div className="right-side">
                  <h2>СВЯЖИТЕСЬ С НАМИ</h2>

                <div className="form">
                  <form method="POST" action="form.php">
                    <div className="inputs">
                      <input
                        type="text"
                        name="name"
                        placeholder="ВАШЕ ИМЯ"
                        value={name}
                        onChange={enterName}
                      />
                    </div>
                    <div>
                      <input
                        type="number"
                        name="phone"
                        placeholder="ВАШ НОМЕР ТЕЛЕФОНА"
                        value={phone}
                        onChange={enterPhone}
                      />
                      <input type="text" name="message" style={{ display: 'none' }} />
                      <input type="submit" value="ОТПРАВИТЬ" />
                    </div>
                  </form>
                </div>

                <div className="socials">
                  <div className="row">
                    <div className="social-item">
                      <a href="https://t.me/dresden777" target="_blank" rel="noopener noreferrer">
                        <img src="/icons/details_icon.svg" alt="" className="click-me" />
                        <div className="icon-wrapper">
                          <img src="/icons/telegram.svg" alt="viber, phone number: 073 562 55 82" style={{ maxWidth: 25, marginRight: 10 }} />
                        </div>
                        <span>073 753 03 03</span>
                      </a>
                    </div>
                    <div className="social-item">
                      <a href="https://www.instagram.com/dresden.dp.ua/" target="_blank" rel="noopener noreferrer">
                        <div className="icon-wrapper">
                          <img src="/icons/instagram-icon.svg" alt="instagram link" />
                        </div>
                        <span>INSTAGRAM</span>
                      </a>
                    </div>
                  </div>

                  <div className="row">
                    <div className="social-item">
                      <a href="https://goo.gl/maps/KxZ5o4wBdwn8uxYC7" target="_blank" rel="noopener noreferrer">
                        <div className="icon-wrapper">
                          <img src="/icons/location-icon.svg" alt="" />
                        </div>
                        <span>Проспект Дружбы народов, г. Каменское (р-н Эпицентра)</span>
                      </a>
                    </div>
                    <div className="social-item">
                      <a href="https://www.facebook.com/dresdendpua-461438367972547/" target="_blank" rel="noopener noreferrer">
                        <div className="icon-wrapper">
                          <img src="/icons/facebook-icon.svg" alt="facebook link" />
                        </div>
                        <span>FACEBOOK</span>
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </Container>
        </div>
      </div>

      <button className="to-top" onClick={() => window.scrollTo(0, 0)}>
        <img src="/icons/arrow.svg" alt="to top of the page" />
      </button>
    </Layout>
  )
};

export default Footer;
