import React from 'react';
import { PillarVertical, PillarHorizontal } from '../pillars';

const Layout = ({ children, ...props }) => {
  return (
    <section {...props}>
        <PillarHorizontal style={{ top: 30, left: 0 }} />
        <PillarVertical style={{ top: 0, left: 30 }} />
        <PillarVertical style={{ top: 0, right: 30 }} />

        { children }
    </section>
  );
};

export default Layout;
